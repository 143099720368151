import axios from 'axios';

const ITUNES_API_URL = 'https://itunes.apple.com/search';
const SPOTIFY_API_URL = 'https://api.spotify.com/v1/search';
const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3/search';

const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const SPOTIFY_CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

let isYouTubeQuotaExceeded = false;
const cache = new Map();

async function getSpotifyToken() {
  try {
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(SPOTIFY_CLIENT_ID + ':' + SPOTIFY_CLIENT_SECRET)
      },
      body: 'grant_type=client_credentials'
    });
    const data = await response.json();
    if (!data.access_token) {
      throw new Error('No access token received from Spotify');
    }
    return data.access_token;
  } catch (error) {
    console.error('Error getting Spotify token:', error);
    throw error;
  }
}

function filterByDuration(episodes, duration) {
  switch (duration) {
    case 'short':
      return episodes.filter(episode => episode.duration_ms < 240000); // Less than 4 minutes
    case 'medium':
      return episodes.filter(episode => episode.duration_ms >= 240000 && episode.duration_ms <= 1200000); // 4-20 minutes
    case 'long':
      return episodes.filter(episode => episode.duration_ms > 1200000); // More than 20 minutes
    default:
      return episodes;
  }
}

async function searchITunesPodcasts(query, language = 'en', duration = 'any') {
  const response = await fetch(`${ITUNES_API_URL}?term=${encodeURIComponent(query)}&entity=podcastEpisode&limit=50&language=${language}`);
  const data = await response.json();
  const episodes = data.results.map(episode => ({
    id: episode.trackId,
    title: episode.trackName,
    description: episode.description,
    showName: episode.collectionName || 'Unknown Show',
    showDescription: episode.collectionDescription || 'No description available',
    url: episode.trackViewUrl,
    releaseDate: new Date(episode.releaseDate).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }),
    language: episode.languageCodesISO2A || language,
    platform: 'iTunes',
    duration_ms: episode.trackTimeMillis,
    image: episode.artworkUrl600
  }));
  return filterByDuration(episodes, duration);
}

async function searchSpotifyPodcasts(query, language = 'en', duration = 'any') {
  try {
    const token = await getSpotifyToken();
    if (!token) {
      console.error('Failed to get Spotify token');
      return [];
    }
    
    const searchUrl = `${SPOTIFY_API_URL}?q=${encodeURIComponent(query)}&type=episode&market=US&limit=50&language=${language}`;
    
    const searchResponse = await fetch(searchUrl, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!searchResponse.ok) {
      throw new Error(`Spotify API responded with status ${searchResponse.status}`);
    }
    
    const searchData = await searchResponse.json();
    
    if (!searchData.episodes || !Array.isArray(searchData.episodes.items)) {
      console.error('Unexpected Spotify API response structure:', searchData);
      return [];
    }
    
    const episodes = searchData.episodes.items.map((episode) => ({
      id: episode.id || 'Unknown ID',
      title: episode.name || 'Unknown Title',
      description: episode.description || 'No description available',
      showName: episode.show?.name || 'Unknown Show',
      showDescription: episode.show?.description || 'No show description available',
      url: episode.external_urls?.spotify || '#',
      releaseDate: new Date(episode.release_date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }) || 'Unknown date',
      language: episode.language || language,
      platform: 'Spotify',
      image: episode.images?.[0]?.url || 'path/to/default/image.jpg',
      duration_ms: episode.duration_ms
    }));

    return filterByDuration(episodes, duration);
  } catch (error) {
    console.error('Error in searchSpotifyPodcasts:', error);
    return [];
  }
}
async function searchYouTubePodcasts(query, language = 'en', duration = 'any', pageToken = '') {
  if (isYouTubeQuotaExceeded) {
    return { items: [], nextPageToken: null };
  }

  const cacheKey = `youtube:${query}:${language}:${duration}:${pageToken}`;
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  try {
    const response = await axios.get(YOUTUBE_API_URL, {
      params: {
        part: 'snippet',
        q: `${query} podcast`,
        type: 'video',
        videoDuration: duration === 'long' ? 'long' : 'any',
        maxResults: 10,
        key: YOUTUBE_API_KEY,
        relevanceLanguage: language,
        pageToken: pageToken,
      },
    });

    const items = response.data.items.map(item => ({
      id: item.id.videoId,
      title: item.snippet.title.replace(/&amp;/g, '&'),
      description: item.snippet.description,
      showName: item.snippet.channelTitle,
      showDescription: 'Visit channel for more information',
      url: `https://www.youtube.com/watch?v=${item.id.videoId}`,
      releaseDate: new Date(item.snippet.publishedAt).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }),
      language: language,
      platform: 'YouTube',
      image: item.snippet.thumbnails.high.url,
      duration_ms: duration === 'long' ? 'Longer than 20 minutes' : 'Duration unknown'
    }));

    const result = { items, nextPageToken: response.data.nextPageToken || null };
    cache.set(cacheKey, result);
    return result;
  } catch (error) {
    console.error('Error in searchYouTubePodcasts:', error);
    if (error.response && error.response.status === 403) {
      isYouTubeQuotaExceeded = true;
    }
    return { items: [], nextPageToken: null };
  }
}

async function searchPodcasts(query, platforms = ['iTunes', 'Spotify', 'YouTube'], language = 'en', duration = 'any', youtubePageToken = '') {
  try {
    const results = {};
    
    await Promise.all(platforms.map(async (platform) => {
      switch (platform) {
        case 'iTunes':
          results.iTunes = await searchITunesPodcasts(query, language, duration);
          break;
        case 'Spotify':
          results.Spotify = await searchSpotifyPodcasts(query, language, duration);
          break;
        case 'YouTube':
          const youtubeResults = await searchYouTubePodcasts(query, language, duration, youtubePageToken);
          results.YouTube = youtubeResults.items;
          results.youtubeNextPageToken = youtubeResults.nextPageToken;
          break;
          default:
        console.warn(`Unsupported platform: ${platform}`);
        return Promise.resolve([]);
      }
    }));

    return { results, youtubeAvailable: !isYouTubeQuotaExceeded };
  } catch (error) {
    console.error('Error in searchPodcasts:', error);
    return { results: {}, youtubeAvailable: false };
  }
}

async function getYouTubeVideoDetails(videoId) {
  const cacheKey = `youtube-details:${videoId}`;
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  try {
    const response = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
      params: {
        part: 'snippet',
        id: videoId,
        key: YOUTUBE_API_KEY,
      },
    });

    const videoDetails = response.data.items[0].snippet;
    cache.set(cacheKey, videoDetails);
    return videoDetails;
  } catch (error) {
    console.error('Error fetching YouTube video details:', error);
    return null;
  }
}

function checkYouTubeQuotaExceeded() {
  return isYouTubeQuotaExceeded;
}

export function clearCache() {
  cache.clear();
}

export { 
  searchPodcasts,
  checkYouTubeQuotaExceeded as isYouTubeQuotaExceeded, 
  getYouTubeVideoDetails 
};


    /* Create an object with platform-specific results
    const platformResults = {};
    platforms.forEach((platform, index) => {
      platformResults[platform] = results[index];
    });

    return platformResults;
  } catch (error) {
    console.error('Error in searchPodcasts:', error);
    return {};
  }
}*/