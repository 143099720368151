import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Card, CardHeader, CardTitle, CardContent } from "./components/ui/card";
import { Checkbox } from "./components/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./components/ui/tabs";
import { Headphones, Star } from 'lucide-react';
import { searchPodcasts, clearCache, isYouTubeQuotaExceeded, getYouTubeVideoDetails } from './api/podcastApi';
import PodcastDescriptionModal from './components/PodcastDescriptionModal';

const ITEMS_PER_PAGE = 15;

const PodcastExplorer = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [platforms, setPlatforms] = useState(['Spotify', 'YouTube']);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [duration, setDuration] = useState('long');
  const [youtubeNextPageToken, setYoutubeNextPageToken] = useState('');
  const [isYouTubeAvailable, setIsYouTubeAvailable] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [platformsWithMoreResults, setPlatformsWithMoreResults] = useState({});
  const [allResultsLoaded, setAllResultsLoaded] = useState(false);
  const [fullDescription, setFullDescription] = useState('');
  const [starredPodcasts, setStarredPodcasts] = useState({});
  const [activeTab, setActiveTab] = useState("search");
  const [starredSearchTerm, setStarredSearchTerm] = useState('');
  const [filteredStarredPodcasts, setFilteredStarredPodcasts] = useState([]);
  const [starredPage, setStarredPage] = useState(1);

  const observer = useRef();
  const starredObserver = useRef();

  const toggleScrollLock = (lock) => {
    if (lock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  useEffect(() => {
    toggleScrollLock(modalOpen);
  
    // Cleanup function to ensure scroll is always enabled when component unmounts
    return () => toggleScrollLock(false);
  }, [modalOpen]);
  useEffect(() => {
    const storedStarredPodcasts = localStorage.getItem('starredPodcasts');
    if (storedStarredPodcasts) {
      setStarredPodcasts(JSON.parse(storedStarredPodcasts));
    }
  }, []);

  useEffect(() => {
    const filtered = Object.values(starredPodcasts).filter(podcast => 
      podcast.title.toLowerCase().includes(starredSearchTerm.toLowerCase()) ||
      podcast.description.toLowerCase().includes(starredSearchTerm.toLowerCase())
    );
    setFilteredStarredPodcasts(filtered);
    setStarredPage(1);
  }, [starredPodcasts, starredSearchTerm]);

  useEffect(() => {
    if (shouldSearch) {
      handleSearch(true);
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  useEffect(() => {
    setIsYouTubeAvailable(!isYouTubeQuotaExceeded());
  }, []);

  const toggleStar = useCallback((podcast) => {
    setStarredPodcasts(prevStarred => {
      const newStarred = { ...prevStarred };
      if (newStarred[podcast.id]) {
        delete newStarred[podcast.id];
      } else {
        newStarred[podcast.id] = podcast;
      }
      localStorage.setItem('starredPodcasts', JSON.stringify(newStarred));
      return newStarred;
    });
  }, []);

  const handleSearch = useCallback(async (isInitialSearch = true) => {
    if (!searchTerm.trim()) return;
    
    setIsLoading(true);
    setError(null);
    if (isInitialSearch) {
      setSearchResults([]);
      setYoutubeNextPageToken('');
      setPlatformsWithMoreResults({});
      setAllResultsLoaded(false);
      clearCache();
    }

    try {
      const { results, youtubeAvailable } = await searchPodcasts(
        searchTerm, 
        platforms, 
        'en', 
        duration, 
        isInitialSearch ? '' : youtubeNextPageToken
      );
      
      setIsYouTubeAvailable(youtubeAvailable);
      
      if (!youtubeAvailable) {
        setPlatforms(prev => prev.filter(p => p !== 'YouTube'));
      }

      let newResults = [];
      let maxResultsPerPlatform = 0;
      let updatedPlatformsWithMoreResults = { ...platformsWithMoreResults };

      Object.entries(results).forEach(([platform, platformResults]) => {
        if (Array.isArray(platformResults) && platformResults.length > 0) {
          if (platformResults.length > maxResultsPerPlatform) {
            maxResultsPerPlatform = platformResults.length;
          }
          updatedPlatformsWithMoreResults[platform] = true;
        } else {
          updatedPlatformsWithMoreResults[platform] = false;
        }
      });

      for (let i = 0; i < maxResultsPerPlatform; i++) {
        platforms.forEach(platform => {
          if (results[platform] && Array.isArray(results[platform]) && results[platform][i]) {
            newResults.push({...results[platform][i], platform});
          }
        });
      }

      setSearchResults(prev => isInitialSearch ? newResults : [...prev, ...newResults]);
      setYoutubeNextPageToken(results.youtubeNextPageToken || '');
      setPlatformsWithMoreResults(updatedPlatformsWithMoreResults);
      
      const hasMoreResults = Object.values(updatedPlatformsWithMoreResults).some(hasMore => hasMore);
      setHasMore(hasMoreResults);
      setAllResultsLoaded(!hasMoreResults);
    } catch (error) {
      console.error('Error searching podcasts:', error);
      setError('An error occurred while searching for podcasts. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, platforms, duration, youtubeNextPageToken, platformsWithMoreResults]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 'search') {
      setShouldSearch(true);
    } else {
      setStarredSearchTerm(e.target.elements.search.value);
    }
  };

  const handleDurationChange = (newDuration) => {
    setDuration(newDuration);
    if (searchTerm.trim()) {
      setShouldSearch(true);
    }
  };

  const handlePlatformChange = (platform) => {
    if (platform === 'YouTube' && !isYouTubeAvailable) {
      return;
    }

    setPlatforms(prev => {
      const newPlatforms = prev.includes(platform)
        ? prev.filter(p => p !== platform)
        : [...prev, platform];
      
      if (searchTerm.trim()) {
        setShouldSearch(true);
      }
      
      return newPlatforms;
    });
  };

  const formatDuration = (durationMs) => {
    if (!durationMs || isNaN(durationMs)) return null;
    const totalMinutes = Math.floor(durationMs / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };

  const handleSeeMore = async (podcast) => {
    setSelectedPodcast(podcast);
    if (podcast.platform === 'YouTube') {
      try {
        const details = await getYouTubeVideoDetails(podcast.id);
        setFullDescription(details.description);
      } catch (error) {
        console.error('Error fetching full YouTube description:', error);
        setFullDescription(podcast.description);
      }
    } else {
      setFullDescription(podcast.description);
    }
    setModalOpen(true);
  };

  const lastPodcastElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        handleSearch(false);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, handleSearch]);

  const lastStarredPodcastElementRef = useCallback(node => {
    if (starredObserver.current) starredObserver.current.disconnect();
    starredObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && filteredStarredPodcasts.length > starredPage * ITEMS_PER_PAGE) {
        setStarredPage(prevPage => prevPage + 1);
      }
    });
    if (node) starredObserver.current.observe(node);
  }, [filteredStarredPodcasts.length, starredPage]);

  const renderPodcastCard = (result, isStarred = false, ref = null) => (
    <Card 
      key={`${result.platform}-${result.id}-${result.title}`} 
      className="overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col"
      ref={ref}
    >
      <CardHeader className="bg-gradient-to-r from-purple-400 to-blue-400 text-white p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-1 mr-2">
            {result.image && (
              <img
                src={result.image}
                alt={result.title}
                className="w-16 h-16 rounded-md mr-4 object-cover"
              />
            )}
            <CardTitle className="text-lg font-bold leading-tight">{result.title}</CardTitle>
          </div>
          <Button
            onClick={() => toggleStar(result)}
            variant="ghost"
            size="icon"
            className="p-1 rounded-full hover:bg-white/20 transition-colors duration-200 bg-transparent"
            aria-label={isStarred ? "Unstar podcast" : "Star podcast"}
          >
            <Star
              className={`h-6 w-6 ${
                isStarred ? 'text-yellow-400 fill-current' : 'text-white stroke-current'
              }`}
              fill={isStarred ? 'currentColor' : 'none'}
              strokeWidth={2}
            />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-4 flex-grow">
        <p className="text-sm mb-2 text-gray-600">
          {result.description.length > 100 
            ? <>
                {result.description.slice(0, 100)}...
                <button 
                  onClick={() => handleSeeMore(result)} 
                  className="text-purple-500 hover:text-purple-600 ml-1 font-medium"
                >
                  See more
                </button>
              </>
            : result.description
          }
        </p>
        <div className="text-xs text-gray-500 mt-2">
          <p>{result.releaseDate}</p>
          {formatDuration(result.duration_ms) && (
            <p>{formatDuration(result.duration_ms)}</p>
          )}
        </div>
      </CardContent>
      <div className="px-4 pb-4 mt-auto">
        <a 
          href={result.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="block w-full text-center bg-purple-500 text-white py-2 px-4 rounded-full hover:bg-purple-600 transition-colors duration-200"
        >
          Listen on {result.platform}
        </a>
      </div>
    </Card>
  );

  return (
    <div className="container mx-auto p-4 bg-gradient-to-b from-purple-100 to-blue-100 min-h-screen">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center text-purple-600 flex items-center justify-center">
          <Headphones className="mr-2" size={36} />
          PodcastExplorer
        </h1>
        
        <Tabs defaultValue="search" onValueChange={setActiveTab} className="mb-6">
          <TabsList className="grid w-full grid-cols-2 mb-4">
            <TabsTrigger value="search">Search</TabsTrigger>
            <TabsTrigger value="starred">Starred</TabsTrigger>
          </TabsList>
          
          <form onSubmit={handleSearchSubmit} className="mb-4 flex">
  <Input
    type="text"
    name="search"
    placeholder={activeTab === 'search' ? "What topics interest you?" : "Search starred podcasts"}
    value={activeTab === 'search' ? searchTerm : starredSearchTerm}
    onChange={(e) => activeTab === 'search' ? setSearchTerm(e.target.value) : setStarredSearchTerm(e.target.value)}
    className="w-full py-2 px-4 rounded-l-full shadow-inner"
  />
  <Button 
    type="submit" 
    className="bg-purple-500 hover:bg-purple-600 text-white rounded-r-full"
  >
    {isLoading ? 'Searching...' : 'Search'}
  </Button>
</form>

          <TabsContent value="search">
            <Card className="mb-8 shadow-lg">
              <CardContent className="p-6">
                <div className="flex flex-wrap gap-4 justify-center mb-4">
                  {['iTunes', 'Spotify', 'YouTube'].map(platform => (
                    (platform !== 'YouTube' || isYouTubeAvailable) && (
                      <div key={platform} className="flex items-center space-x-2">
                        <Checkbox
                          id={platform.toLowerCase()}
                          checked={platforms.includes(platform)}
                          onCheckedChange={() => handlePlatformChange(platform)}
                          className="w-6 h-6"
                        />
                        <label htmlFor={platform.toLowerCase()} className="text-sm font-medium cursor-pointer">{platform}</label>
                      </div>
                    )
                  ))}
                </div>
                <Select value={duration} onValueChange={handleDurationChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select duration" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="any">Any duration</SelectItem>
                    <SelectItem value="short">Short (&lt; 4 min)</SelectItem>
                    <SelectItem value="medium">Medium (4-20 min)</SelectItem>
                    <SelectItem value="long">Long (&gt; 20 min)</SelectItem>
                  </SelectContent>
                </Select>
              </CardContent>
            </Card>
            {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {searchResults.map((result, index) => 
                renderPodcastCard(
                  result, 
                  !!starredPodcasts[result.id],
                  index === searchResults.length - 1 ? lastPodcastElementRef : null
                )
              )}
            </div>
            {isLoading && <p className="text-center mt-4">Loading more results...</p>}
            {allResultsLoaded && <p className="text-center mt-4">End of results</p>}
          </TabsContent>
          
          <TabsContent value="starred">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredStarredPodcasts.slice(0, starredPage * ITEMS_PER_PAGE).map((podcast, index) => 
                renderPodcastCard(
                  podcast, 
                  true,
                  index === starredPage * ITEMS_PER_PAGE - 1 ? lastStarredPodcastElementRef : null
                )
              )}
            </div>
            {filteredStarredPodcasts.length === 0 && (
              <p className="text-center mt-4">No starred podcasts found. Start exploring to add some!</p>
            )}
          </TabsContent>
        </Tabs>
        
        <PodcastDescriptionModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            title={selectedPodcast?.title}
            description={fullDescription}
          />
      </div>
    </div>
  );
};

export default PodcastExplorer;